import React, { useState, useContext } from "react"
import speedVideo from "../videos/endeavour45.mp4"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./speedBike.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const SpeedBike = () => {
  const [activeSizeOption, setActiveSizeOption] = useState("M")
  const [activeTab, setActiveTab] = useState(1)
  const [geometryToggle, setGeometryToggle] = useState(true)
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      allStrapiSpeed(filter: { titre: { eq: "Kalkhoff Edeavour 45" } }) {
        nodes {
          images {
            url
          }
        }
      }
      moteur: file(relativePath: { eq: "images/moteur/moteur.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      batterie: file(relativePath: { eq: "images/batterie/batterie.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kalkhoffGeometry: file(
        relativePath: { eq: "images/speed/kalkhoffGeometry.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Les speedbikes électriques"
        description="Sélection des speedbikes électriques"
      />
      <main id="topOfPage" className={styles.mainContainer}>
        <section className={styles.topSection}>
          <h4 className="dispo">En stock</h4>
          <h1 className="category">Speedbikes</h1>
        </section>
        <div className={styles.upperBracket}></div>
        <section className={styles.bikeVisuals}>
          <SwiperSlider data={data.allStrapiSpeed} />
        </section>
        <div className={styles.lowerBracket}></div>
        <section className={styles.bikeSpecs}>
          <section className={styles.specs1}>
            <h3>KALKHOFF ENDEAVOUR 7.B EXCITE 45</h3>
            <ul>
              <AnchorLink to="/speedBike#moteur">
                <li>
                  <div className={styles.cross}></div>
                  <p>Moteur Bosch Perf. Line</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/speedBike#batterie">
                <li>
                  <div className={styles.cross}></div>
                  <p>Batterie Bosch 625 wh</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/speedBike#freins">
                <li>
                  <div className={styles.cross}></div>
                  <p>Vitesse : 45 km/h !</p>
                </li>
              </AnchorLink>
              <li>
                <div className={styles.circle}></div>
                <p>Freins à disque Magura MT5</p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Phare Supernova M99</p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Poids(modèle M) : 27kg </p>
              </li>
            </ul>
          </section>
          <section className={styles.specs2}>
            <h4 className={styles.price}>4 699,00 €</h4>
            <section className={styles.quantity}>
              <label htmlFor="taille">Taille</label>
              <section className={styles.quantityOptionsContainer}>
                <div className={styles.selectionShape}></div>
                <div
                  onClick={e => setActiveSizeOption("XS")}
                  onKeyDown={e => e.key === 1 ?? setActiveSizeOption("XS")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "XS"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  XS
                </div>
                <div
                  onClick={e => setActiveSizeOption("S")}
                  onKeyDown={e => e.key === 2 ?? setActiveSizeOption("S")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "S"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  S
                </div>
                <div
                  onClick={e => setActiveSizeOption("M")}
                  onKeyDown={e => e.key === 3 ?? setActiveSizeOption("M")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "M"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  M
                </div>
                <div
                  onClick={e => setActiveSizeOption("L")}
                  onKeyDown={e => e.key === 4 ?? setActiveSizeOption("L")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "L"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  L
                </div>
                <div
                  onClick={e => setActiveSizeOption("XL")}
                  onKeyDown={e => e.key === 5 ?? setActiveSizeOption("XL")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeSizeOption !== "XL"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  XL
                </div>
              </section>
            </section>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Khalkhoff Endeavour 7b",
                  `${activeSizeOption}`,
                  1,
                  4699,
                  "speed/speed.jpg"
                )
              }
              value="Acheter"
            />
          </section>
        </section>
        <section id="moteur" className={styles.specs3}>
          <article className={styles.specs3a}>
            <h2>Moteur Bosh</h2>
            <br />
            <section className={styles.frame2}>
              <Img
                className={styles.image}
                fluid={data.moteur.childImageSharp.fluid}
                alt="moteur pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              La motorisation Performance Line représente le système de
              motorisation le plus polyvalent de Bosch : qu'il s'agisse de
              rouler à travers champs à une allure sportive, de se rendre au
              travail ou de se balader en toute décontraction pendant ses
              loisirs, elle offre toujours la bonne dose d'assistance au bon
              moment. Elle est disponible en deux versions. La motorisation
              Performance Cruise assiste jusqu'à 25 km/h grâce à son couple
              maximal de 65 Nm et séduit par son comportement au pédalage
              naturel et sportif. Ainsi, le plaisir de rouler est absolu, même
              sur terrain vallonné. La motorisation Performance Speed permet
              d'atteindre des vitesses élevées encore plus rapidement, pour
              couvrir de longues distances sans forcer. Elle assiste les VAE
              Speed jusqu'à 45 km/h et garantit des trajets quotidiens encore
              plus rapides à partir de l'année-modèle 2021 grâce à un couple
              maximal de 85 Nm.
            </p>
          </article>
        </section>
        <section id="batterie" className={styles.specs4}>
          <article className={styles.specs4a}>
            <h2>Batterie 625wh</h2>
            <br />
            <section className={styles.frame3}>
              <Img
                className={styles.image}
                fluid={data.batterie.childImageSharp.fluid}
                alt="batterie pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              Le summum : avec la nouvelle batterie PowerTube 625, partez pour
              de longues excursions en montagne et disposez de toute l'énergie
              nécessaire pour chaque aventure. Grâce à sa capacité nominale de
              16,7 Ah et à sa puissance d'environ 625 Wh, cette batterie
              lithium-ion vous offre la garantie d'une autonomie maximale et
              vous aide à franchir facilement tous les dénivelés. Avec ses
              fixations solides, la PowerTube 625 ne tombe pas même sur les
              terrains difficiles, tout en restant facile à retirer. Après
              environ 3,7 h, la batterie est entièrement rechargée au moyen du
              Fast Charger 6 A. Pour une demi-charge, comptez environ 1,4 heure.
            </p>
          </article>
        </section>
        <section className={styles.upSection}>
          <AnchorLink className={styles.topOfPage} to="/speedBike#topOfPage">
            <div className={styles.upArrow}></div>
          </AnchorLink>
        </section>
        <section id="freins" className={styles.specs5}>
          <article>
            <h2>Vitesse : 45 km/h !</h2>
            <br />
            <p className={styles.text}>
              Le KALKHOFF ENDEAVOUR 7.B EXCITE 45 fait partie de la catégorie
              "Speed Bike", c'est à dire que l'assistance au pédalage se fera
              jusqu'à atteindre 45 km/h. Il s'agit d'un vrai bolide super agile,
              idéal pour se rendre au travail et vite rendrer chez soi.
            </p>
          </article>
        </section>
        <section className={styles.videoSection}>
          <section className={styles.videoContainer}>
            <video controls>
              <source src={speedVideo} type="video/mp4" />
              <track
                label="English"
                kind="captions"
                srclang="en"
                default
              ></track>
            </video>
          </section>
        </section>
        <section className={styles.specs6}>
          <section className={styles.tabTitles}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(1)}
              onKeyDown={e => e.key === "a" ?? setActiveTab(1)}
              className={
                activeTab === 1 ? styles.activeTabTitle1 : styles.tabTitle
              }
            >
              Equipement
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(2)}
              onKeyDown={e => e.key === "b" ?? setActiveTab(2)}
              className={
                activeTab === 2 ? styles.activeTabTitle2 : styles.tabTitle
              }
            >
              Moteur et <br /> batterie
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(3)}
              onKeyDown={e => e.key === "c" ?? setActiveTab(3)}
              className={
                activeTab === 3 ? styles.activeTabTitle3 : styles.tabTitle
              }
            >
              Géométrie
            </div>
          </section>
          <section className={styles.tabs}>
            {activeTab === 1 && (
              <section className={styles.tab1}>
                <table>
                  <tbody>
                    <tr>
                      <th>Couleur</th>
                      <td>action team</td>
                    </tr>
                    <tr>
                      <th>Cadre</th>
                      <td>
                        C:62 Monocoque Advanced Twin Mold Technology, Aluminum
                        6061 T6 Rear Triangle, Efficient Trail Control, FSP
                        4-Link, Agile Trail Geometry, Boost 148, Full Integrated
                        Battery
                      </td>
                    </tr>
                    <tr>
                      <th>Fourche</th>
                      <td>
                        Fox 36 Float Factory FIT4, 3-Position w/Low Speed
                        Compression Adjust, Tapered, 15x110mm, E-Bike Optimized,
                        150mm, Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Amort-
                        <br />
                        isseur
                      </th>
                      <td>
                        Fox Float DPX2 Factory EVOL, 185x55mm,
                        Open(Adjustable)/Medium/Firm Mode, Trunnion Mount,
                        Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>Freins</th>
                      <td>Shimano XT BR-M8120, Hydr. Disc Brake (203/203)</td>
                    </tr>
                    <tr>
                      <th>Selle</th>
                      <td>Natural Fit Venec</td>
                    </tr>
                    <tr>
                      <th>Tige de selle</th>
                      <td>
                        Fox Transfer Factory 31.6mm, Kashima Coated (XS, S:
                        100mm, M: 125mm, L: 150mm, XL: 175mm)
                      </td>
                    </tr>
                    <tr>
                      <th>Pédalier</th>
                      <td>
                        e*thirteen Plus Crank, 165mm (27.5: 36T // 29: 34T)
                      </td>
                    </tr>
                    <tr>
                      <th>Dérailleur arrière</th>
                      <td>Shimano XT RD-M8100-SGS, ShadowPlus, 12-Speed</td>
                    </tr>
                    <tr>
                      <th>Cassette</th>
                      <td>Shimano Deore CS-M6100, 10-51T</td>
                    </tr>
                    <tr>
                      <th>Pneu avant</th>
                      <td>
                        Schwalbe Magic Mary, Super Trail, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Pneu arrière</th>
                      <td>
                        Schwalbe Big Betty, Super Gravity, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Ecran</th>
                      <td>Bosch Nyon</td>
                    </tr>
                    <tr>
                      <th>Taille des roues</th>
                      <td>
                        Size Split: 27.5: XS (15") // 29: S (16"), M (18"), L
                        (20"), XL (22")
                      </td>
                    </tr>
                    <tr>
                      <th>Poids total</th>
                      <td>à partir de 24,3 kg</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}

            {activeTab === 2 && (
              <section className={styles.tab2}>
                <h2>Moteur</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Type de transmission</th>
                      <td>Dérailleur/Moyeu</td>
                    </tr>
                    <tr>
                      <th>Niveau d'assistance (%)</th>
                      <td>
                        TURBO: 340 <br />
                        eMTB: 140-340 <br />
                        TOUR: 140 <br />
                        ECO: 60
                      </td>
                    </tr>
                    <tr>
                      <th>Couple maximal possible (Nm)</th>
                      <td>
                        TURBO: 85 <br />
                        eMTB: 85 <br />
                        TOUR: 50 <br />
                        ECO: 40
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Assistance jusque dans les fréquences
                        <br />
                        de pédalage maximales (tr/min)
                      </th>
                      <td>&gt; 120</td>
                    </tr>
                    <tr>
                      <th>Comportement au démarrage</th>
                      <td>Très sportif</td>
                    </tr>
                    <tr>
                      <th>Assistance maximale jusqu'à</th>
                      <td>25 km/h</td>
                    </tr>
                    <tr>
                      <th>Détection des changements de vitesse</th>
                      <td>Dynamique</td>
                    </tr>
                    <tr>
                      <th>Poids</th>
                      <td>2,9 kg</td>
                    </tr>
                  </tbody>
                </table>
                <h2>Batterie</h2>
                <table className={styles.batterieTable}>
                  <thead>
                    <tr>
                      <th aria-label="thead"></th>
                      <th>Compact Charger</th>
                      <th>Standard Charger</th>
                      <th>Fast Charger</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>50 % Charge</th>
                      <td>env. 4,2h</td>
                      <td>env. 2,1h</td>
                      <td>env. 1,4h</td>
                    </tr>
                    <tr>
                      <th>100 % charge</th>
                      <td>env. 8,8h</td>
                      <td>env. 4,9h</td>
                      <td>env. 3,7h</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {activeTab === 3 && (
              <section className={styles.tab3}>
                <section className={styles.switchSection}>
                  <h3>Tableau</h3>
                  <div
                    aria-label="toggleTabletoDrawing"
                    role="button"
                    tabIndex="0"
                    onClick={() => setGeometryToggle(!geometryToggle)}
                    onKeyDown={e =>
                      e.key === "t"
                        ? setGeometryToggle(!geometryToggle)
                        : setGeometryToggle(geometryToggle)
                    }
                    className={
                      geometryToggle
                        ? styles.tgl
                        : `${styles.tgl} ${styles.tglRight}`
                    }
                  ></div>
                  <h3>Dessin technique</h3>
                </section>
                {geometryToggle && (
                  <section className={styles.cubeStereoGeometryTable}>
                    <table>
                      <thead>
                        <tr>
                          <th aria-label="thead"></th>
                          <th>16"</th>
                          <th>18"</th>
                          <th>20"</th>
                          <th>22"</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Tube de selle</th>
                          <td>375</td>
                          <td>420</td>
                          <td>470</td>
                          <td>520</td>
                        </tr>
                        <tr>
                          <th>Tube supérieur</th>
                          <td>571</td>
                          <td>592</td>
                          <td>616</td>
                          <td>642</td>
                        </tr>
                        <tr>
                          <th>Angle de tube de direction</th>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                        </tr>
                        <tr>
                          <th>Base arrière</th>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                        </tr>
                        <tr>
                          <th>Tube de direction</th>
                          <td>105</td>
                          <td>108</td>
                          <td>118</td>
                          <td>138</td>
                        </tr>
                        <tr>
                          <th>Empattement</th>
                          <td>1189</td>
                          <td>1210</td>
                          <td>1235</td>
                          <td>1262</td>
                        </tr>
                        <tr>
                          <th>Débattement arrière</th>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                        </tr>
                        <tr>
                          <th>Hauteur de l'enjambement</th>
                          <td>777</td>
                          <td>777</td>
                          <td>777</td>
                          <td>813</td>
                        </tr>
                        <tr>
                          <th>Taille de roue</th>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                )}
                {!geometryToggle && (
                  <section className={styles.cubeStereoGeometrySvg}>
                    <Img
                      className={styles.image}
                      fluid={data.kalkhoffGeometry.childImageSharp.fluid}
                      alt="."
                    />
                  </section>
                )}
              </section>
            )}
          </section>
        </section>
      </main>
    </Layout>
  )
}

export default SpeedBike
